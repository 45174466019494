<template>
    <a-modal
      :visible="show"
      :footer="null"
      width="839px"
      @ok="closeModal(dataInfo)"
      @cancel="closeModal(dataInfo)"
      :bodyStyle="bodyStyle"
    >
        <a-row>
            <span style="padding-top:35px;display: block;text-align:center;font-size: 22px;font-weight: 500;">{{dataInfo.invoice_type | toReceiptType}}</span>
        </a-row>
        <a-row style="padding-left:22px;color:#333333;font-weight:500;">
            <a-col span="24">
                <span style="font-size: 16px;">开票信息</span>
            </a-col>
            <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                <a-col span="14"><span class="item_header">订单编号：</span>{{dataInfo.order_no}}</a-col>
                <a-col span="10"><span class="item_header">发票金额：</span>￥{{dataInfo.total_amount | toPrice}}</a-col>
            </a-col>
            <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                <a-col span="14"><span class="item_header">发票类型：</span><span style="color:#F31414;font-weight:500;">{{dataInfo.invoice_type | toReceiptType}}</span></a-col>
                <a-col span="10"><span class="item_header">发票内容：</span><span style="font-weight:500;">版权服务费</span></a-col>
            </a-col>
            <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                <a-col span="14"><span class="item_header">发票抬头类型：</span>{{dataInfo.title_type | toReceiptHeaderType}}</a-col>
                <a-col span="10"></a-col>
            </a-col>
            <a-col v-if="dataInfo.title_type === $val.ReceiptTitleType.company" span="24">
                <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                    <a-col span="14"><span class="item_header">发票抬头：</span>{{dataInfo.title ? dataInfo.title.name : ''}}</a-col>
                    <a-col span="10"><span class="item_header">纳税人识别号：</span>{{dataInfo.title ? dataInfo.title.taxer_number: ''}}</a-col>
                </a-col>
                <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                    <a-col span="14"><span class="item_header">注册地址：</span>{{dataInfo.title ? dataInfo.title.address: ''}}</a-col>
                    <a-col span="10"><span class="item_header">开户银行：</span>{{dataInfo.title ? dataInfo.title.bank: ''}}</a-col>
                </a-col>
                <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                    <a-col span="14"><span class="item_header">注册电话：</span>{{dataInfo.title ? dataInfo.title.phone: ''}}</a-col>
                    <a-col span="10"><span class="item_header">银行账号：</span>{{dataInfo.title ? dataInfo.title.account: ''}}</a-col>
                </a-col>
            </a-col>
            <a-col v-else span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                <a-col span="14"><span class="item_header">个人抬头：</span>{{dataInfo.title ? dataInfo.title.name : ''}}</a-col>
                <a-col span="10"></a-col>
            </a-col>
            <a-col span="24" style="margin-top:22px;color:#333333;">
                <span style="font-size: 16px;">收票信息</span>
            </a-col>
            <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;"  v-if="dataInfo.invoice_type < $val.ReceiptType.commonEelec">
                <a-col span="14"><span class="item_header">收票人姓名：</span>{{dataInfo.express ? dataInfo.express.name : ''}}</a-col>
                <a-col span="10"><span class="item_header">收票人手机号：</span>{{dataInfo.express ? dataInfo.express.phone : ''}}</a-col>
            </a-col>
            <a-col span="24" style="font-size: 14px;margin-top:22px;color:#333333;">
                <a-col span="14" v-if="dataInfo.invoice_type >= $val.ReceiptType.commonEelec"><span class="item_header">收票邮箱：</span>{{dataInfo.express ? dataInfo.express.email : ''}}</a-col>
                <a-col span="14" v-else><span class="item_header">收票地址：</span>{{dataInfo.express ? dataInfo.express.address : ''}}</a-col>
                <a-col span="10"></a-col>
            </a-col>
        </a-row>
        <a-row>
            <a-col span="24" v-if="dataInfo.is_operated==1" style="color: #999999;font-weight:400;line-height: 66px;background: #F2F9FC;border-radius: 0px 0px 6px 6px;margin-top:22px;text-align:center;">
                <span v-if="dataInfo.invoice_type >= $val.ReceiptType.commonEelec">您的发票【已发送至邮箱】，请注意查收</span>
                <span v-else>您的发票【已封装寄送】，请注意查收</span>
            </a-col>
            <a-col v-else style="color: #999999;font-weight:400;line-height: 66px;background: #FFF0E5;border-radius: 0px 0px 6px 6px;margin-top:22px;text-align:center;">
                <span>您的发票【正在审核】，请耐心等待</span>
            </a-col>
        </a-row>
    </a-modal>
</template>
<script>
  import {Modal as AModal} from 'ant-design-vue';
  import {toOptionName} from '@/utils/function';
  import {ReceiptHeaderTypeOptions, ReceiptTypeOptions} from '@/config/options';
  const Status = {
    success: 'success',
    wait: 'wait',
    init: 'init'
  }
  export default {
    data() {
      return {
        show: false,
        Status,
        dataInfo: {},
        bodyStyle:{
            "padding": 0,
        },
      }
    },
    watch: {
      visible: {
        handler(newVal) {
          this.show = newVal;
        },
        immediate: true
      },
      invoice_id: {
        handler(newVal) {
          newVal && this.getReceiptInfo({invoice_id: newVal})
        },
        immediate: true
      }
    },
    props: {
      visible: Boolean,
      data: Object,
      invoice_id: String
    },
    filters: {
      toReceiptHeaderType(value) {
        return toOptionName(ReceiptHeaderTypeOptions, value);
      },
      toReceiptType(value) {
        return toOptionName(ReceiptTypeOptions, value);
      },
    },
    computed: {
      receiptStatus() {
        let status = this.dataInfo.is_operated;

        return status === this.$val.CommonCheck.fail ? Status.wait : status === this.$val.CommonCheck.correct ? Status.success : Status.init;
      },

      statusDir() {
        let ot = this.receiptStatus;
        let headerStatus = 'wait';
        let dialogStatus = 'primary';
        let headerTitle = '发票申请中';
        let headerSubTitle = '';
        let headerSubText = '管理员会在 15 个工作日内向你邮寄发票，请注意查收';

        if (ot === Status.success) {
          headerStatus = 'success';
          dialogStatus = 'success';
          headerTitle = '发票申请成功';
          headerSubTitle = '订单编号';
          headerSubText = this.data.order_no;
        }

        return {headerStatus, dialogStatus, headerTitle, headerSubTitle, headerSubText}
      },
    }, 
    components: {
      AModal,
    },
    methods: {
        getReceiptInfo(params) {
            this.$axios.Order_receiptInfo(params).then(res => {
                const data = res.data;
                if (data && data.code == 0) {
                this.dataInfo = data.data ? data.data : {}
                }
            })
        },
        closeModal(dataInfo){
            this.$emit("update:visible", false);
        }
    },
  }
</script>

<style lang="scss" scoped>
  .item_header{
      color: #999999;
      width: 30%;
      text-align:right;
      display:inline-block;
  }
</style>